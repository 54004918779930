import ReactRoller from 'react-random-roller';
import WorldzRoller from "../components/WorldzRoller";
import {Snowfall} from "react-snowfall";
import {useEffect, useState} from "react";
import axiosInstance from "../api/axiosInstance";
import axios from "axios";
import PrimaryButton from "../components/PrimaryButton";
import CommonReward from "../components/rewards/commonReward";
import RareReward from "../components/rewards/rareReward";
import EpicReward from "../components/rewards/epicReward";
import LegendaryReward from "../components/rewards/legendaryReward";

export default function NewYearRoller(props) {

    const [tokensAmount, setTokensAmount] = useState(null);
    const [authorized, setAuthorized] = useState(false);
    const [proccessing, setProccessing] = useState(true);
    const [error, setError] = useState(null);
    const [spinning, setSpinning] = useState(false);
    const [rollerIdReward, setRollerIdReward] = useState(0);

    const [rewardId, setRewardId] = useState(null);
    const [rewardPromo, setRewardPromo] = useState(null);

    useEffect(() => {
        setError(null);
        if (localStorage.getItem('token')) {
            setAuthorized(true);
        }
        axios.get('https://vpn.back.worldz.tech/api/ny/tokens/?token=' + localStorage.getItem('token'))
            .then((response) => {
                setTokensAmount(response.data.tokens);
                setProccessing(false);
            })
            .catch((error) => {
                setError("Возникла ошибка. Возможно, ваша сессия закончилась. Авторизуйтесь снова.")
                setProccessing(false);
            })

    }, [])

    const spinRoller = () => {
        setSpinning(true)
        let interval = setInterval(() => {

            setRollerIdReward((Math.floor(Math.random() * 10)))

            console.log(rollerIdReward % rewards.length)
        }, 10)

        setTimeout(() => {
            axios.post("https://vpn.back.worldz.tech/api/ny/tokens/spend/", {
                token: localStorage.getItem('token'),
            })
                .then((response) => {
                    setRewardId(response.data.reward_index)
                    setRewardPromo(response.data.promo)
                    setTokensAmount(response.data.tokens)
                    clearInterval(interval);
                    setSpinning(false);
                })
                .catch((error) => {
                    setError(error.response.data.error);
                })
        }, 3000)
    }

    const [rewards, setRewards] = useState([
        {
            name: "2 дня подписки",
            innerName: "sub_2",
            elem: <CommonReward amount={2} label={'дня подписки'}/>
        },
        {
            name: "3 дня подписки",
            innerName: "sub_3",
            elem: <CommonReward amount={3} label={'дня подписки'}/>
        },
        {
            name: "4 дня подписки",
            innerName: "sub_4",
            elem: <CommonReward amount={4} label={'дня подписки'}/>
        },
        {
            name: "5 дней подписки",
            innerName: "sub_5",
            elem: <CommonReward amount={5} label={'дней подписки'}/>
        },
        {
            name: "10 дней подписки",
            innerName: "sub_10",
            elem: <RareReward amount={10} label={'дней подписки'}/>
        },
        {
            name: "15 дней подписки",
            innerName: "sub_15",
            elem: <RareReward amount={15} label={'дней подписки'}/>
        },
        {
            name: "20 дней подписки",
            innerName: "sub_20",
            elem: <EpicReward amount={20} label={'дней подписки'}/>
        },
        {
            name: "25 дней подписки",
            innerName: "sub_25",
            elem: <EpicReward amount={25} label={'дней подписки'}/>
        },
        {
            name: "30 дней подписки",
            innerName: "sub_30",
            elem: <EpicReward amount={30} label={'дней подписки'}/>
        },
        {
            name: "180 дней подписки",
            innerName: "sub_180",
            elem: <LegendaryReward amount={180} label={'дней подписки'}/>
        },
    ]);

    return (
        <div className={'container m-auto text-center min-h-screen flex flex-col items-center justify-center'}>
            <Snowfall/>
            <div>
                <h2 className={'text-5xl'}>С Наступающим!</h2>
                <p>WorldzVPN подготвил для вас множество подарков! Крутите роллер и пусть вам повезет!</p>
            </div>

            <div className={'mt-20'}>
                <div>
                    {
                        proccessing ? (
                            <div><i className='bx bxs-tree bx-spin'></i></div>
                        ) : (
                            error ? (
                                <div className={'text-red-500'}>
                                    <p>{error}</p>
                                </div>
                            ) : (
                                <>
                                    {
                                        !spinning && (
                                            (rewardPromo && rewardId) && (
                                                <div>
                                                    <p>Поздравляем! Вот ваш подарок!</p>
                                                    <div className={'mt-10 mb-10'}>
                                                        {rewards[rewardId].elem}
                                                    </div>

                                                    <p>Промокод отправили в чат Beep.</p>
                                                </div>
                                            )
                                        )

                                    }
                                    {
                                        authorized ? (
                                            tokensAmount > 0 ? (
                                                <div>

                                                    <div>
                                                        {
                                                            spinning ? (
                                                                rewards[rollerIdReward] && (
                                                                    rewards[rollerIdReward].elem && (
                                                                        rewards[rollerIdReward].elem
                                                                    )
                                                                )
                                                            ) : (
                                                                <>
                                                                    <p>Жетонов осталось: {tokensAmount}</p>
                                                                    < PrimaryButton onClick={spinRoller}>Запустить
                                                                        роллер!</PrimaryButton>
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            ) : (
                                                <div>
                                                    <p>У вас закончились жетоны Новогоднего Роллера.</p>
                                                    <p>Не переживайте! Они скоро обновятся.</p>
                                                </div>
                                            )

                                        ) : (
                                            <div>
                                                <p>Кажется, вы не авторизованы. Давайте исправим!</p>
                                                <a href={'https://personal.vpn.worldz.tech/'}><PrimaryButton>Авторизоваться</PrimaryButton></a>
                                            </div>
                                        )
                                    }
                                </>
                            )

                        )
                    }

                </div>
            </div>
        </div>
    )
}