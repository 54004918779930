import logo from './logo.svg';
import './App.css';
import InputField from "./components/InputField";
import {useEffect, useState} from "react";
import axios from "axios";
import PrimaryButton from "./components/PrimaryButton";
import 'boxicons'
import axiosInstance from "./api/axiosInstance";
import SetupApp from "./Fullscreen Messages/SetupApp";
import HowToInstallOnIos from "./Fullscreen Messages/HowToInstallOnIos";

function App() {

    const [LoginKeyId, setLoginKeyId] = useState("");
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [beepCode, setBeepCode] = useState(null);
    const [verifyCode, setVerifyCode] = useState("");

    const [showSetup, setShowSetup] = useState(false);
    const [showHowIos, setShowHowIos] = useState(false);

    const [inputCode, setInputCode] = useState(true);

    const [inputKeyId, setInputKeyId] = useState(true);

    const [token, setToken] = useState(null);

    const [deferred, setDeferred] = useState(null);

    useEffect(() => {
        if (LoginKeyId.length === 9) {
            getBeepCode()
        }
    }, [LoginKeyId])

    useEffect(() => {
        if (verifyCode.length === 4) {
            VerifyLogin()
        }
    }, [verifyCode]);


    useEffect(() => {
        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();
            setDeferred(e)

            setShowSetup(true);
        });
    }, [])

    async function digestMessage(message) {
        const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
        const hashBuffer = await window.crypto.subtle.digest("SHA-256", msgUint8); // hash the message
        const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
        const hashHex = hashArray
            .map((b) => b.toString(16).padStart(2, "0"))
            .join(""); // convert bytes to hex string
        return hashHex;
    }

    const getBeepCode = () => {
        setError(null);
        setSuccess(null);
        setInputKeyId(false);
        axios.get(`https://vpn.back.worldz.tech/api/getbeep?keyId=${LoginKeyId}`)
            .then((response) => {
                localStorage.setItem("beepCode", response.data.beep);
                setBeepCode(response.data.beep);
            })
            .catch((error) => {
                setError("Не удалось получить информацию о ключе, пожалуйста, проверьте введеный номер.")
                setInputKeyId(true);
            })
    }

    const sendVerifyCode = () => {
        let bCode = localStorage.getItem("beepCode");
        axios.get(`https://beep.api.worldz.tech/api/auth/5bf3a3b8-040d-4d32-96af-7dd57c456889/${bCode}/acode/`)
            .then((response) => {
                setSuccess("Код успешно отправлен")
            })
            .catch((error) => {
                setError("Ошибка при отправке кода");
            })
    }

    const VerifyLogin = () => {
        axios.post(`https://beep.api.worldz.tech/api/auth/5bf3a3b8-040d-4d32-96af-7dd57c456889/${beepCode}/acode/`, {
            "code": verifyCode
        })
            .then((response) => {
                localStorage.setItem("token", response.data.token);
                setToken(response.data.token);
                setSuccess("Получилось! Проверяем целостность данных.")
            })
            .catch((error) => {
                setError("Кажется вы ошиблись. Если нет, то ошиблись мы. Исправимся.");
                setInputCode(true)
            })
    }


    useEffect(() => {
        if (beepCode) {
            sendVerifyCode()
        }
    }, [beepCode])

    useEffect(() => {

        if (token) {
            console.log(token)
            setError(null);
            setSuccess(null);
            axiosInstance.get("check_auth")
                .then((response) => {
                    window.location = '/personal'
                })
                .catch((error) => {
                    setBeepCode(null)
                    setToken(null)
                    setInputKeyId(true)
                    setInputCode(true)
                    setError("Неверные данные авторизации или истекла сессия. Авторизуйтесь снова.")
                    localStorage.clear()
                })
        }
    }, [token])

    useEffect(() => {
        if (localStorage.getItem("token")) {
            setToken(localStorage.getItem("token"));
        } else {
            if (localStorage.getItem("beepCode")) {
                setBeepCode(localStorage.getItem("beepCode"));
            }
        }


    }, [])

    return (
        <>
            <div className={'h-screen w-screen p-5 flex flex-col justify-center items-center'}>
                <h1 className={'text-6xl'}>Worldz VPN</h1>
                <p className={'text-4xl bg-gradient-to-br from-sky-400 to-fuchsia-500 inline-block text-transparent bg-clip-text'}>personal</p>
                <div className={'mt-5 text-center'}>
                    {
                        beepCode ? (
                            <>
                                {
                                    inputCode ? (
                                        <>
                                            <p>Мы отправили код подтверждения через Beep. Загляните в чат.</p>
                                            <InputField setSourceData={setVerifyCode} textCenter={true}/>
                                        </>
                                    ) : (
                                        <>
                                            <box-icon name='loader-alt' animation='spin' color='#ffffff'></box-icon>
                                        </>
                                    )
                                }

                            </>
                        ) : (
                            <>
                                {
                                    inputKeyId ? (
                                        <>
                                            <p>Введите номер ключа</p>
                                            <p className={'text-sm text-slate-500'}>Вы можете найти его в чате Beep,
                                                номер
                                                ключа указан в счете</p>
                                            <InputField setSourceData={setLoginKeyId} textCenter={true}/>
                                            <p>Еще нет ключа? <a href={'https://vpn.worldz.tech/key'}><u>Давайте
                                                создадим!</u></a></p>
                                        </>
                                    ) : (
                                        <box-icon name='loader-alt' animation='spin' color='#ffffff'></box-icon>
                                    )
                                }

                            </>
                        )
                    }

                    <p className={'text-red-500'}>{error}</p>
                    <p className={'text-green-500'}>{success}</p>

                </div>
                <p className={'text-slate-500 text-center'} onClick={() => {
                    setShowHowIos(true)
                }}><u>Как установить приложение Worldz VPN Perosnal на iOS?</u></p>

                <p className={'cursor-pointer mt-5'} onClick={() => {
                    localStorage.clear()
                    document.location.reload()
                }}>Войти в другой аккаунт</p>
            </div>

            <SetupApp setShow={setShowSetup} isShow={showSetup} onClick={() => {
                deferred.prompt()
                deferred.userChoice.then((choiceResult) => {
                    if (choiceResult.outcome === 'accepted') {
                        console.log('User accepted the install prompt');
                    } else {
                        console.log('User dismissed the install prompt');
                    }
                    setDeferred(null)
                })
            }}/>

            <HowToInstallOnIos setShow={setShowHowIos} isShow={showHowIos}/>
        </>
    );
}

export default App;
