import FullscreenMessage from "../components/FullscreenMessage";
import InputField from "../components/InputField";
import {useState} from "react";
import PrimaryButton from "../components/PrimaryButton";
import DangerButton from "../components/DangerButton";
import axios from "axios";
import axiosInstance from "../api/axiosInstance";

export default function SetLabelWindow({isShow, setShow, keyData, reloadView}) {

    const [label, setLabel] = useState(null);
    const [done, setDone] = useState(false);
    const [error, setError] = useState("");

    const cancel = () => {
        setLabel(null)
        setDone(false)
        setShow(false)
    }

    const setLabelRequest = (src) => {
        setError(null);
        const content = src.innerHTML
        src.innerHTML = "<i class='bx bx-loader-alt bx-spin' ></i>"
        src.disabled = true;
        axiosInstance.post('set_label/', {
            token: localStorage.getItem('token'),
            key_id: keyData.key_id,
            label: label
        })
            .then((response) => {
                setDone(true);
            })
            .catch((error) => {
                setError("Возникла ошибка. Попробуйте еще раз. " + error)
            })
            .finally(() => {
                src.innerHTML = content
                src.disabled = false
            })
    }

    return (
        <>
            {
                keyData && (
                    <FullscreenMessage isShow={isShow}>
                        {
                            done ? (
                                <div
                                    className={'h-screen text-center gap-5 flex flex-col justify-center items-center'}>
                                    <i className='bx bxs-check-circle bx-tada text-9xl text-green-500'></i>
                                    <p className={'text-3xl'}>Готово!</p>
                                    <p>Ярлык установлен</p>
                                    <PrimaryButton onClick={reloadView}>Супер!</PrimaryButton>
                                </div>
                            ) : (
                                <div className={'h-screen text-center gap-5 flex flex-col justify-center items-center'}>
                                    <i className='bx bxs-purchase-tag-alt bx-tada text-9xl'></i>
                                    <p className={'text-3xl'}>Устанавливаем ярлык</p>
                                    <div>
                                        <p>для ключа <span className={'text-xl'}>{keyData.key_id}</span></p>
                                        <p className={'text-sm text-slate-500'}>проверьте номер ключа</p>
                                    </div>

                                    <div className={'mt-5'}>
                                        <p>Введите название</p>
                                        <InputField maxLen={20} defaultValue={label} setSourceData={setLabel}
                                                    placeholder={'Для бабули'}
                                                    textCenter={true}/>
                                        {
                                            label ? (
                                                <p className={'text-sm'}>Осталось {20 - label.length} знаков</p>
                                            ) : (
                                                <p className={'text-sm'}>Максимум - 20 знаков</p>
                                            )
                                        }

                                    </div>

                                    <div className={'flex gap-5'}>
                                        <PrimaryButton onClick={setLabelRequest}>Установить</PrimaryButton>
                                        <DangerButton onClick={cancel}>Отменить</DangerButton>
                                    </div>

                                    <p className={'text-red-500'}>{error}</p>
                                </div>
                            )
                        }
                    </FullscreenMessage>
                )
            }
        </>

    )
}