import FullscreenMessage from "../components/FullscreenMessage";
import PrimaryButton from "../components/PrimaryButton";
import DangerButton from "../components/DangerButton";
import InputField from "../components/InputField";
import {useEffect, useState} from "react";
import SelectedKeyData from "../BottomPages/SelectedKeyData";
import SelectField from "../components/SelectField";
import axios from "axios";
import axiosInstance from "../api/axiosInstance";

export default function PromocodeActivation({isShow, setShow, onClick, keyList}) {

    const [promo, setPromo] = useState("");
    const [selectablesValues, setSelectablesValues] = useState(null);
    const [selectableNames, setSelectableNames] = useState(null);
    const [selectedKeyId, setSelectedKeyId] = useState(null);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);

    const activatePromocode = () => {
        setError(null);
        axios.post("https://vpn.back.worldz.tech/api/activate_promotion_code/", {
            code: promo,
            key_id: selectedKeyId
        })
            .then(() => {
                setSuccess(true);
            })
            .catch((error) => {
                setError(error.response.data.error);
            })
    }

    useEffect(() => {
        let keysIds = []
        let keysNames = []

        keyList.forEach(key => {
            keysIds.push(key.key_id)

            if (key.label) {
                keysNames.push(key.label)
            } else {
                keysNames.push(key.key_id)
            }
        })
        setSelectableNames(keysNames)
        setSelectablesValues(keysIds)
    }, [keyList]);

    return (
        <FullscreenMessage isShow={isShow}>
            <div className={'h-screen text-center gap-5 flex flex-col justify-center items-center'}>
                {
                    success ? (
                        <>
                            <i className='bx bxs-party bx-tada text-9xl'></i>
                            <p className={'text-3xl'}>Та дам!</p>
                            <p>Промокод применен!</p>
                            <PrimaryButton onClick={() => {
                                setSuccess(false);
                                setShow(false)
                            }}>Ура!</PrimaryButton>
                        </>
                    ) : (
                        <>
                            <i className='bx bxs-discount bx-tada text-9xl'></i>
                            <p className={'text-5xl'}>Активация промокода</p>
                            <p>Всегда приятно получать подарки! А когда они еще и бесплатные - вообще песня!</p>

                            <div>
                                <p>Выбери ключ</p>
                                <SelectField selectableValues={selectablesValues} selectableNames={selectableNames}
                                             setSource={setSelectedKeyId}/>
                            </div>

                            <div>
                                <p>Введи промокод</p>
                                <InputField setSourceData={setPromo}/>
                            </div>

                            {
                                selectedKeyId && (
                                    <PrimaryButton onClick={activatePromocode}>Активировать</PrimaryButton>
                                )
                            }

                            {
                                error && (
                                    <p className={'text-red-500'}>Что-то пошло не так: {error}</p>
                                )
                            }

                            <DangerButton onClick={() => setShow(false)}>Позже</DangerButton>
                        </>
                    )
                }

            </div>
        </FullscreenMessage>
    )
}