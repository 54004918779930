export default function SelectField({selectableValues, selectableNames, setSource}) {
    return (
        <select className={'p-3 bg-slate-500 text-xl rounded-xl mt-5 mb-5'} onChange={(e) => setSource(e.target.value)}>
            <option value="" disabled selected>Ключ</option>
            {
                selectableValues && selectableNames && (
                    selectableValues.map((value, index) => (
                        <option key={index} value={value}>{selectableNames[index]}</option>
                    ))
                )
            }
        </select>
    )
}