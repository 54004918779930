import {useEffect, useState} from "react";
import axiosInstance from "./api/axiosInstance";
import TopUpNotify from "./components/TopUpNotify";
import BottomPage from "./components/BottomPage";
import BottomPageCaller from "./components/BottomPageCaller";
import KeyCard from "./components/KeyCard";
import SelectedKeyData from "./BottomPages/SelectedKeyData";
import ShowBottomPage from "./BottomPages/controller/BottomPageController";
import WhatIsLabel from "./Fullscreen Messages/WhatIsLabel";
import SetLabelWindow from "./Fullscreen Messages/SetLabelWindow";
import PaymentWindow from "./Fullscreen Messages/PaymentWindow";
import PromocodeActivation from "./Fullscreen Messages/PromocodeActivation";
import PrimaryButton from "./components/PrimaryButton";
import axios from "axios";
import tree from './media/tree.svg'

export default function Personal() {

    const [keyList, setKeyList] = useState([]);
    const [selectedKeyData, setSelectedKeyData] = useState(null);
    const [showKeyDataPage, setShowKeyDataPage] = useState(false);

    // MODALS
    const [whatIsLabelVisible, setWhatIsLabelVisble] = useState(false);
    const [setLabelVisible, setSetLabelVisible] = useState(false);
    const [paymentVisible, setPaymentVisible] = useState(false);
    const [promocodeVisible, setPromocodeVisible] = useState(false);

    const [gameTokens, setGameTokens] = useState(0);

    const ResetView = () => {
        window.location.reload()
    }

    useEffect(() => {
        axiosInstance.get('/keys?token=' + localStorage.getItem('token'))
            .then((response) => {
                setKeyList(response.data.keydata);
            })
            .catch((error) => {
                console.log(error);
            })
        axios.get('https://vpn.back.worldz.tech/api/ny/tokens/?token=' + localStorage.getItem('token'))
            .then((response) => {
                setGameTokens(response.data.tokens);
            })
        const interval = setInterval(() => {
            axiosInstance.get('/keys?token=' + localStorage.getItem('token'))
                .then((response) => {
                    setKeyList(response.data.keydata);
                })
                .catch((error) => {
                    console.log(error);
                })
        }, 3000);
        return () => clearInterval(interval);
    }, [])

    return (
        <>
            {/*END NOTIFY UI*/}
            <div className={'h-screen w-screen border-0 border-green-500 p-10'} id={'overlay-canvas'}>
                <div className={'flex justify-between items-center text-3xl'}>
                    <h2>Личный кабинет</h2>
                    <div className={'flex gap-5'}>
                        <a href={'https://vpn.worldz.tech/key'} target={'_blank'}><i
                            className='bx bxs-add-to-queue cursor-pointer'></i></a>
                        <i className='bx bxs-discount cursor-pointer' onClick={() => setPromocodeVisible(true)}></i>
                    </div>
                </div>

                {/*KEYS*/}
                <div className={'mt-5 flex flex-col gap-5'}>

                    {/*NEW YEAR BANNER*/}
                    <div className={'bg-black text-white p-5 rounded-xl relative overflow-hidden'}>
                        <h2 className={'text-3xl'}>Новогодний Роллер</h2>
                        <p>А что это у вас на носу! Новый Год! Хо-хо-хо! Крутите Новогодний роллер и получайте
                            промокоды. Магия? Нет, доброта душевная.</p>

                        <div className={'mt-5 flex flex-col gap-0'}>
                            <label>Ваши жетоны: {gameTokens}</label>
                            <a href={'/newyear'}><PrimaryButton>Крутануть Роллер</PrimaryButton></a>
                        </div>

                        <img src={tree} className={'absolute h-64 bottom-0 end-0 translate-x-1/2 translate-y-1/3'}/>
                    </div>
                    {/*END NEW YEAR BANNER*/}
                    {
                        keyList.map((item, index) =>
                            <KeyCard keyData={item} key={index} onClickAct={() => {
                                setSelectedKeyData(item);
                                setShowKeyDataPage(true);
                            }}/>
                        )
                    }
                </div>


            </div>

            {
                (showKeyDataPage && selectedKeyData) && (
                    <SelectedKeyData setKeyList={setKeyList} keyData={selectedKeyData} setShow={setShowKeyDataPage}
                                     setShowPayment={setPaymentVisible} setSetLabelShow={setSetLabelVisible}
                                     setWhatIsLabelShow={setWhatIsLabelVisble}/>
                )
            }

            <WhatIsLabel isShow={whatIsLabelVisible} setShow={setWhatIsLabelVisble}/>
            <SetLabelWindow isShow={setLabelVisible} setShow={setSetLabelVisible} reloadView={ResetView}
                            keyData={selectedKeyData}/>
            <PaymentWindow isShow={paymentVisible} setShow={setPaymentVisible}/>
            <PromocodeActivation isShow={promocodeVisible} setShow={setPromocodeVisible} keyList={keyList}/>
        </>

    )

}